import _definePage_default_0 from '/vercel/path0/src/pages/[...error].vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/vercel/path0/src/pages/access-control.vue?definePage&vue'
import _definePage_default_3 from '/vercel/path0/src/pages/agency/settings.vue?definePage&vue'
import _definePage_default_4 from '/vercel/path0/src/pages/amazon/callback.vue?definePage&vue'
import _definePage_default_5 from '/vercel/path0/src/pages/apps/chat.vue?definePage&vue'
import _definePage_default_6 from '/vercel/path0/src/pages/apps/email/index.vue?definePage&vue'
import _definePage_default_7 from '/vercel/path0/src/pages/apps/logistics/fleet.vue?definePage&vue'
import _definePage_default_8 from '/vercel/path0/src/pages/auth/reset-password.vue?definePage&vue'
import _definePage_default_9 from '/vercel/path0/src/pages/client/dashboard.vue?definePage&vue'
import _definePage_default_10 from '/vercel/path0/src/pages/client/settings.vue?definePage&vue'
import _definePage_default_11 from '/vercel/path0/src/pages/dashboard/index.vue?definePage&vue'
import _definePage_default_12 from '/vercel/path0/src/pages/dashboard/printable.vue?definePage&vue'
import _definePage_default_13 from '/vercel/path0/src/pages/forgot-password.vue?definePage&vue'
import _definePage_default_14 from '/vercel/path0/src/pages/front-pages/checkout.vue?definePage&vue'
import _definePage_default_15 from '/vercel/path0/src/pages/front-pages/help-center/index.vue?definePage&vue'
import _definePage_default_16 from '/vercel/path0/src/pages/front-pages/help-center/article/[title].vue?definePage&vue'
import _definePage_default_17 from '/vercel/path0/src/pages/front-pages/landing-page/index.vue?definePage&vue'
import _definePage_default_18 from '/vercel/path0/src/pages/front-pages/payment.vue?definePage&vue'
import _definePage_default_19 from '/vercel/path0/src/pages/front-pages/pricing.vue?definePage&vue'
import _definePage_default_20 from '/vercel/path0/src/pages/googleads/manage-connection.vue?definePage&vue'
import _definePage_default_21 from '/vercel/path0/src/pages/googleads/oauthcallback.vue?definePage&vue'
import _definePage_default_22 from '/vercel/path0/src/pages/linkedin/callback.vue?definePage&vue'
import _definePage_default_23 from '/vercel/path0/src/pages/login.vue?definePage&vue'
import _definePage_default_24 from '/vercel/path0/src/pages/meta/callback.vue?definePage&vue'
import _definePage_default_25 from '/vercel/path0/src/pages/not-authorized.vue?definePage&vue'
import _definePage_default_26 from '/vercel/path0/src/pages/onboarding/index.vue?definePage&vue'
import _definePage_default_27 from '/vercel/path0/src/pages/pages/account-settings/[tab].vue?definePage&vue'
import _definePage_default_28 from '/vercel/path0/src/pages/pages/authentication/forgot-password-v1.vue?definePage&vue'
import _definePage_default_29 from '/vercel/path0/src/pages/pages/authentication/forgot-password-v2.vue?definePage&vue'
import _definePage_default_30 from '/vercel/path0/src/pages/pages/authentication/login-v1.vue?definePage&vue'
import _definePage_default_31 from '/vercel/path0/src/pages/pages/authentication/login-v2.vue?definePage&vue'
import _definePage_default_32 from '/vercel/path0/src/pages/pages/authentication/register-multi-steps.vue?definePage&vue'
import _definePage_default_33 from '/vercel/path0/src/pages/pages/authentication/register-v1.vue?definePage&vue'
import _definePage_default_34 from '/vercel/path0/src/pages/pages/authentication/register-v2.vue?definePage&vue'
import _definePage_default_35 from '/vercel/path0/src/pages/pages/authentication/reset-password-v1.vue?definePage&vue'
import _definePage_default_36 from '/vercel/path0/src/pages/pages/authentication/reset-password-v2.vue?definePage&vue'
import _definePage_default_37 from '/vercel/path0/src/pages/pages/authentication/two-steps-v1.vue?definePage&vue'
import _definePage_default_38 from '/vercel/path0/src/pages/pages/authentication/two-steps-v2.vue?definePage&vue'
import _definePage_default_39 from '/vercel/path0/src/pages/pages/authentication/verify-email-v1.vue?definePage&vue'
import _definePage_default_40 from '/vercel/path0/src/pages/pages/authentication/verify-email-v2.vue?definePage&vue'
import _definePage_default_41 from '/vercel/path0/src/pages/pages/client/[id]/[tab].vue?definePage&vue'
import _definePage_default_42 from '/vercel/path0/src/pages/pages/connect-[id].vue?definePage&vue'
import _definePage_default_43 from '/vercel/path0/src/pages/pages/misc/coming-soon.vue?definePage&vue'
import _definePage_default_44 from '/vercel/path0/src/pages/pages/misc/under-maintenance.vue?definePage&vue'
import _definePage_default_45 from '/vercel/path0/src/pages/pages/new-report/[id].vue?definePage&vue'
import _definePage_default_46 from '/vercel/path0/src/pages/pages/pricing/callback/index.vue?definePage&vue'
import _definePage_default_47 from '/vercel/path0/src/pages/pages/pricing/callback.vue?definePage&vue'
import _definePage_default_48 from '/vercel/path0/src/pages/pages/report/public-report.vue?definePage&vue'
import _definePage_default_49 from '/vercel/path0/src/pages/pages/user-profile/[tab].vue?definePage&vue'
import _definePage_default_50 from '/vercel/path0/src/pages/register.vue?definePage&vue'
import _definePage_default_51 from '/vercel/path0/src/pages/setting/index.vue?definePage&vue'
import _definePage_default_52 from '/vercel/path0/src/pages/shopify/callback.vue?definePage&vue'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/:error(.*)',
    name: '$error',
    component: () => import('/vercel/path0/src/pages/[...error].vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  _mergeRouteRecord(
  {
    path: '/access-control',
    name: 'access-control',
    component: () => import('/vercel/path0/src/pages/access-control.vue'),
    /* no children */
  },
  _definePage_default_2
  ),
  {
    path: '/agency',
    /* internal name: 'agency' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: 'settings',
        name: 'agency-settings',
        component: () => import('/vercel/path0/src/pages/agency/settings.vue'),
        /* no children */
      },
  _definePage_default_3
  )
    ],
  },
  {
    path: '/amazon',
    /* internal name: 'amazon' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: 'callback',
        name: 'amazon-callback',
        component: () => import('/vercel/path0/src/pages/amazon/callback.vue'),
        /* no children */
      },
  _definePage_default_4
  )
    ],
  },
  {
    path: '/apps',
    /* internal name: 'apps' */
    /* no component */
    children: [
      {
        path: 'academy',
        /* internal name: 'apps-academy' */
        /* no component */
        children: [
          {
            path: 'course-details',
            name: 'apps-academy-course-details',
            component: () => import('/vercel/path0/src/pages/apps/academy/course-details.vue'),
            /* no children */
          },
          {
            path: 'dashboard',
            name: 'apps-academy-dashboard',
            component: () => import('/vercel/path0/src/pages/apps/academy/dashboard.vue'),
            /* no children */
          },
          {
            path: 'my-course',
            name: 'apps-academy-my-course',
            component: () => import('/vercel/path0/src/pages/apps/academy/my-course.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'calendar',
        name: 'apps-calendar',
        component: () => import('/vercel/path0/src/pages/apps/calendar.vue'),
        /* no children */
      },
  _mergeRouteRecord(
      {
        path: 'chat',
        name: 'apps-chat',
        component: () => import('/vercel/path0/src/pages/apps/chat.vue'),
        /* no children */
      },
  _definePage_default_5
  ),
      {
        path: 'ecommerce',
        /* internal name: 'apps-ecommerce' */
        /* no component */
        children: [
          {
            path: 'customer',
            /* internal name: 'apps-ecommerce-customer' */
            /* no component */
            children: [
              {
                path: 'details',
                /* internal name: 'apps-ecommerce-customer-details' */
                /* no component */
                children: [
                  {
                    path: ':id',
                    name: 'apps-ecommerce-customer-details-id',
                    component: () => import('/vercel/path0/src/pages/apps/ecommerce/customer/details/[id].vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'list',
                /* internal name: 'apps-ecommerce-customer-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'apps-ecommerce-customer-list',
                    component: () => import('/vercel/path0/src/pages/apps/ecommerce/customer/list/index.vue'),
                    /* no children */
                  }
                ],
              }
            ],
          },
          {
            path: 'manage-review',
            name: 'apps-ecommerce-manage-review',
            component: () => import('/vercel/path0/src/pages/apps/ecommerce/manage-review.vue'),
            /* no children */
          },
          {
            path: 'order',
            /* internal name: 'apps-ecommerce-order' */
            /* no component */
            children: [
              {
                path: 'details',
                /* internal name: 'apps-ecommerce-order-details' */
                /* no component */
                children: [
                  {
                    path: ':id',
                    name: 'apps-ecommerce-order-details-id',
                    component: () => import('/vercel/path0/src/pages/apps/ecommerce/order/details/[id].vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'list',
                /* internal name: 'apps-ecommerce-order-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'apps-ecommerce-order-list',
                    component: () => import('/vercel/path0/src/pages/apps/ecommerce/order/list/index.vue'),
                    /* no children */
                  }
                ],
              }
            ],
          },
          {
            path: 'product',
            /* internal name: 'apps-ecommerce-product' */
            /* no component */
            children: [
              {
                path: 'add',
                /* internal name: 'apps-ecommerce-product-add' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'apps-ecommerce-product-add',
                    component: () => import('/vercel/path0/src/pages/apps/ecommerce/product/add/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'category-list',
                name: 'apps-ecommerce-product-category-list',
                component: () => import('/vercel/path0/src/pages/apps/ecommerce/product/category-list.vue'),
                /* no children */
              },
              {
                path: 'list',
                /* internal name: 'apps-ecommerce-product-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'apps-ecommerce-product-list',
                    component: () => import('/vercel/path0/src/pages/apps/ecommerce/product/list/index.vue'),
                    /* no children */
                  }
                ],
              }
            ],
          },
          {
            path: 'referrals',
            name: 'apps-ecommerce-referrals',
            component: () => import('/vercel/path0/src/pages/apps/ecommerce/referrals.vue'),
            /* no children */
          },
          {
            path: 'settings',
            name: 'apps-ecommerce-settings',
            component: () => import('/vercel/path0/src/pages/apps/ecommerce/settings.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'email',
        /* internal name: 'apps-email' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'apps-email',
            component: () => import('/vercel/path0/src/pages/apps/email/index.vue'),
            /* no children */
          },
  _definePage_default_6
  )
        ],
      },
      {
        path: 'invoice',
        /* internal name: 'apps-invoice' */
        /* no component */
        children: [
          {
            path: 'add',
            /* internal name: 'apps-invoice-add' */
            /* no component */
            children: [
              {
                path: '',
                name: 'apps-invoice-add',
                component: () => import('/vercel/path0/src/pages/apps/invoice/add/index.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'edit',
            /* internal name: 'apps-invoice-edit' */
            /* no component */
            children: [
              {
                path: ':id',
                name: 'apps-invoice-edit-id',
                component: () => import('/vercel/path0/src/pages/apps/invoice/edit/[id].vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'list',
            /* internal name: 'apps-invoice-list' */
            /* no component */
            children: [
              {
                path: '',
                name: 'apps-invoice-list',
                component: () => import('/vercel/path0/src/pages/apps/invoice/list/index.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'preview',
            /* internal name: 'apps-invoice-preview' */
            /* no component */
            children: [
              {
                path: ':id',
                name: 'apps-invoice-preview-id',
                component: () => import('/vercel/path0/src/pages/apps/invoice/preview/[id].vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'logistics',
        /* internal name: 'apps-logistics' */
        /* no component */
        children: [
          {
            path: 'dashboard',
            name: 'apps-logistics-dashboard',
            component: () => import('/vercel/path0/src/pages/apps/logistics/dashboard.vue'),
            /* no children */
          },
  _mergeRouteRecord(
          {
            path: 'fleet',
            name: 'apps-logistics-fleet',
            component: () => import('/vercel/path0/src/pages/apps/logistics/fleet.vue'),
            /* no children */
          },
  _definePage_default_7
  )
        ],
      },
      {
        path: 'permissions',
        /* internal name: 'apps-permissions' */
        /* no component */
        children: [
          {
            path: '',
            name: 'apps-permissions',
            component: () => import('/vercel/path0/src/pages/apps/permissions/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'roles',
        /* internal name: 'apps-roles' */
        /* no component */
        children: [
          {
            path: '',
            name: 'apps-roles',
            component: () => import('/vercel/path0/src/pages/apps/roles/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'user',
        /* internal name: 'apps-user' */
        /* no component */
        children: [
          {
            path: 'list',
            /* internal name: 'apps-user-list' */
            /* no component */
            children: [
              {
                path: '',
                name: 'apps-user-list',
                component: () => import('/vercel/path0/src/pages/apps/user/list/index.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'view',
            /* internal name: 'apps-user-view' */
            /* no component */
            children: [
              {
                path: ':id',
                name: 'apps-user-view-id',
                component: () => import('/vercel/path0/src/pages/apps/user/view/[id].vue'),
                /* no children */
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/apps/email/:filter',
    name: 'apps-email-filter',
    component: () => import('/src/pages/apps/email/index.vue'),
    /* no children */
  },
  {
    path: '/apps/email/:label',
    name: 'apps-email-label',
    component: () => import('/src/pages/apps/email/index.vue'),
    /* no children */
  },
  {
    path: '/auth',
    /* internal name: 'auth' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: 'reset-password',
        name: 'auth-reset-password',
        component: () => import('/vercel/path0/src/pages/auth/reset-password.vue'),
        /* no children */
      },
  _definePage_default_8
  )
    ],
  },
  {
    path: '/charts',
    /* internal name: 'charts' */
    /* no component */
    children: [
      {
        path: 'apex-chart',
        name: 'charts-apex-chart',
        component: () => import('/vercel/path0/src/pages/charts/apex-chart.vue'),
        /* no children */
      },
      {
        path: 'chartjs',
        name: 'charts-chartjs',
        component: () => import('/vercel/path0/src/pages/charts/chartjs.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/client',
    /* internal name: 'client' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: 'dashboard',
        name: 'client-dashboard',
        component: () => import('/vercel/path0/src/pages/client/dashboard.vue'),
        /* no children */
      },
  _definePage_default_9
  ),
  _mergeRouteRecord(
      {
        path: 'settings',
        name: 'client-settings',
        component: () => import('/vercel/path0/src/pages/client/settings.vue'),
        /* no children */
      },
  _definePage_default_10
  )
    ],
  },
  {
    path: '/components',
    /* internal name: 'components' */
    /* no component */
    children: [
      {
        path: 'alert',
        name: 'components-alert',
        component: () => import('/vercel/path0/src/pages/components/alert.vue'),
        /* no children */
      },
      {
        path: 'avatar',
        name: 'components-avatar',
        component: () => import('/vercel/path0/src/pages/components/avatar.vue'),
        /* no children */
      },
      {
        path: 'badge',
        name: 'components-badge',
        component: () => import('/vercel/path0/src/pages/components/badge.vue'),
        /* no children */
      },
      {
        path: 'button',
        name: 'components-button',
        component: () => import('/vercel/path0/src/pages/components/button.vue'),
        /* no children */
      },
      {
        path: 'chip',
        name: 'components-chip',
        component: () => import('/vercel/path0/src/pages/components/chip.vue'),
        /* no children */
      },
      {
        path: 'dialog',
        name: 'components-dialog',
        component: () => import('/vercel/path0/src/pages/components/dialog.vue'),
        /* no children */
      },
      {
        path: 'expansion-panel',
        name: 'components-expansion-panel',
        component: () => import('/vercel/path0/src/pages/components/expansion-panel.vue'),
        /* no children */
      },
      {
        path: 'list',
        name: 'components-list',
        component: () => import('/vercel/path0/src/pages/components/list.vue'),
        /* no children */
      },
      {
        path: 'menu',
        name: 'components-menu',
        component: () => import('/vercel/path0/src/pages/components/menu.vue'),
        /* no children */
      },
      {
        path: 'pagination',
        name: 'components-pagination',
        component: () => import('/vercel/path0/src/pages/components/pagination.vue'),
        /* no children */
      },
      {
        path: 'progress-circular',
        name: 'components-progress-circular',
        component: () => import('/vercel/path0/src/pages/components/progress-circular.vue'),
        /* no children */
      },
      {
        path: 'progress-linear',
        name: 'components-progress-linear',
        component: () => import('/vercel/path0/src/pages/components/progress-linear.vue'),
        /* no children */
      },
      {
        path: 'snackbar',
        name: 'components-snackbar',
        component: () => import('/vercel/path0/src/pages/components/snackbar.vue'),
        /* no children */
      },
      {
        path: 'tabs',
        name: 'components-tabs',
        component: () => import('/vercel/path0/src/pages/components/tabs.vue'),
        /* no children */
      },
      {
        path: 'timeline',
        name: 'components-timeline',
        component: () => import('/vercel/path0/src/pages/components/timeline.vue'),
        /* no children */
      },
      {
        path: 'Todo',
        name: 'components-todo',
        component: () => import('/vercel/path0/src/pages/components/Todo.vue'),
        /* no children */
      },
      {
        path: 'tooltip',
        name: 'components-tooltip',
        component: () => import('/vercel/path0/src/pages/components/tooltip.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/dashboard',
    /* internal name: 'dashboard' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'dashboard',
        component: () => import('/vercel/path0/src/pages/dashboard/index.vue'),
        /* no children */
      },
  _definePage_default_11
  ),
  _mergeRouteRecord(
      {
        path: 'printable',
        name: 'dashboard-printable',
        component: () => import('/vercel/path0/src/pages/dashboard/printable.vue'),
        /* no children */
      },
  _definePage_default_12
  )
    ],
  },
  {
    path: '/dashboards',
    /* internal name: 'dashboards' */
    /* no component */
    children: [
      {
        path: 'analytics',
        name: 'dashboards-analytics',
        component: () => import('/vercel/path0/src/pages/dashboards/analytics.vue'),
        /* no children */
      },
      {
        path: 'client',
        name: 'dashboards-client',
        component: () => import('/vercel/path0/src/pages/dashboards/client.vue'),
        /* no children */
      },
      {
        path: 'crm',
        name: 'dashboards-crm',
        component: () => import('/vercel/path0/src/pages/dashboards/crm.vue'),
        /* no children */
      },
      {
        path: 'ecommerce',
        name: 'dashboards-ecommerce',
        component: () => import('/vercel/path0/src/pages/dashboards/ecommerce.vue'),
        /* no children */
      },
      {
        path: 'test',
        name: 'dashboards-test',
        component: () => import('/vercel/path0/src/pages/dashboards/test.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/extensions',
    /* internal name: 'extensions' */
    /* no component */
    children: [
      {
        path: 'swiper',
        name: 'extensions-swiper',
        component: () => import('/vercel/path0/src/pages/extensions/swiper.vue'),
        /* no children */
      },
      {
        path: 'tour',
        name: 'extensions-tour',
        component: () => import('/vercel/path0/src/pages/extensions/tour.vue'),
        /* no children */
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('/vercel/path0/src/pages/forgot-password.vue'),
    /* no children */
  },
  _definePage_default_13
  ),
  {
    path: '/forms',
    /* internal name: 'forms' */
    /* no component */
    children: [
      {
        path: 'autocomplete',
        name: 'forms-autocomplete',
        component: () => import('/vercel/path0/src/pages/forms/autocomplete.vue'),
        /* no children */
      },
      {
        path: 'checkbox',
        name: 'forms-checkbox',
        component: () => import('/vercel/path0/src/pages/forms/checkbox.vue'),
        /* no children */
      },
      {
        path: 'combobox',
        name: 'forms-combobox',
        component: () => import('/vercel/path0/src/pages/forms/combobox.vue'),
        /* no children */
      },
      {
        path: 'custom-input',
        name: 'forms-custom-input',
        component: () => import('/vercel/path0/src/pages/forms/custom-input.vue'),
        /* no children */
      },
      {
        path: 'date-time-picker',
        name: 'forms-date-time-picker',
        component: () => import('/vercel/path0/src/pages/forms/date-time-picker.vue'),
        /* no children */
      },
      {
        path: 'editors',
        name: 'forms-editors',
        component: () => import('/vercel/path0/src/pages/forms/editors.vue'),
        /* no children */
      },
      {
        path: 'file-input',
        name: 'forms-file-input',
        component: () => import('/vercel/path0/src/pages/forms/file-input.vue'),
        /* no children */
      },
      {
        path: 'form-layouts',
        name: 'forms-form-layouts',
        component: () => import('/vercel/path0/src/pages/forms/form-layouts.vue'),
        /* no children */
      },
      {
        path: 'form-validation',
        name: 'forms-form-validation',
        component: () => import('/vercel/path0/src/pages/forms/form-validation.vue'),
        /* no children */
      },
      {
        path: 'form-wizard-icons',
        name: 'forms-form-wizard-icons',
        component: () => import('/vercel/path0/src/pages/forms/form-wizard-icons.vue'),
        /* no children */
      },
      {
        path: 'form-wizard-numbered',
        name: 'forms-form-wizard-numbered',
        component: () => import('/vercel/path0/src/pages/forms/form-wizard-numbered.vue'),
        /* no children */
      },
      {
        path: 'radio',
        name: 'forms-radio',
        component: () => import('/vercel/path0/src/pages/forms/radio.vue'),
        /* no children */
      },
      {
        path: 'range-slider',
        name: 'forms-range-slider',
        component: () => import('/vercel/path0/src/pages/forms/range-slider.vue'),
        /* no children */
      },
      {
        path: 'rating',
        name: 'forms-rating',
        component: () => import('/vercel/path0/src/pages/forms/rating.vue'),
        /* no children */
      },
      {
        path: 'select',
        name: 'forms-select',
        component: () => import('/vercel/path0/src/pages/forms/select.vue'),
        /* no children */
      },
      {
        path: 'slider',
        name: 'forms-slider',
        component: () => import('/vercel/path0/src/pages/forms/slider.vue'),
        /* no children */
      },
      {
        path: 'switch',
        name: 'forms-switch',
        component: () => import('/vercel/path0/src/pages/forms/switch.vue'),
        /* no children */
      },
      {
        path: 'textarea',
        name: 'forms-textarea',
        component: () => import('/vercel/path0/src/pages/forms/textarea.vue'),
        /* no children */
      },
      {
        path: 'textfield',
        name: 'forms-textfield',
        component: () => import('/vercel/path0/src/pages/forms/textfield.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/front-pages',
    /* internal name: 'front-pages' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: 'checkout',
        name: 'front-pages-checkout',
        component: () => import('/vercel/path0/src/pages/front-pages/checkout.vue'),
        /* no children */
      },
  _definePage_default_14
  ),
      {
        path: 'help-center',
        /* internal name: 'front-pages-help-center' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'front-pages-help-center',
            component: () => import('/vercel/path0/src/pages/front-pages/help-center/index.vue'),
            /* no children */
          },
  _definePage_default_15
  ),
          {
            path: 'article',
            /* internal name: 'front-pages-help-center-article' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':title',
                name: 'front-pages-help-center-article-title',
                component: () => import('/vercel/path0/src/pages/front-pages/help-center/article/[title].vue'),
                /* no children */
              },
  _definePage_default_16
  )
            ],
          }
        ],
      },
      {
        path: 'landing-page',
        /* internal name: 'front-pages-landing-page' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'front-pages-landing-page',
            component: () => import('/vercel/path0/src/pages/front-pages/landing-page/index.vue'),
            /* no children */
          },
  _definePage_default_17
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'payment',
        name: 'front-pages-payment',
        component: () => import('/vercel/path0/src/pages/front-pages/payment.vue'),
        /* no children */
      },
  _definePage_default_18
  ),
  _mergeRouteRecord(
      {
        path: 'pricing',
        name: 'front-pages-pricing',
        component: () => import('/vercel/path0/src/pages/front-pages/pricing.vue'),
        /* no children */
      },
  _definePage_default_19
  )
    ],
  },
  {
    path: '/googleads',
    /* internal name: 'googleads' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: 'manage-connection',
        name: 'googleads-manage-connection',
        component: () => import('/vercel/path0/src/pages/googleads/manage-connection.vue'),
        /* no children */
      },
  _definePage_default_20
  ),
  _mergeRouteRecord(
      {
        path: 'oauthcallback',
        name: 'googleads-oauthcallback',
        component: () => import('/vercel/path0/src/pages/googleads/oauthcallback.vue'),
        /* no children */
      },
  _definePage_default_21
  )
    ],
  },
  {
    path: '/linkedin',
    /* internal name: 'linkedin' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: 'callback',
        name: 'linkedin-callback',
        component: () => import('/vercel/path0/src/pages/linkedin/callback.vue'),
        /* no children */
      },
  _definePage_default_22
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/login',
    name: 'login',
    component: () => import('/vercel/path0/src/pages/login.vue'),
    /* no children */
  },
  _definePage_default_23
  ),
  {
    path: '/meta',
    /* internal name: 'meta' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: 'callback',
        name: 'meta-callback',
        component: () => import('/vercel/path0/src/pages/meta/callback.vue'),
        /* no children */
      },
  _definePage_default_24
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('/vercel/path0/src/pages/not-authorized.vue'),
    /* no children */
  },
  _definePage_default_25
  ),
  {
    path: '/onboarding',
    /* internal name: 'onboarding' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'onboarding',
        component: () => import('/vercel/path0/src/pages/onboarding/index.vue'),
        /* no children */
      },
  _definePage_default_26
  )
    ],
  },
  {
    path: '/pages',
    /* internal name: 'pages' */
    /* no component */
    children: [
      {
        path: 'access-mgmt',
        /* internal name: 'pages-access-mgmt' */
        /* no component */
        children: [
          {
            path: '',
            name: 'pages-access-mgmt',
            component: () => import('/vercel/path0/src/pages/pages/access-mgmt/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'account-settings',
        /* internal name: 'pages-account-settings' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':tab',
            name: 'pages-account-settings-tab',
            component: () => import('/vercel/path0/src/pages/pages/account-settings/[tab].vue'),
            /* no children */
          },
  _definePage_default_27
  )
        ],
      },
      {
        path: 'authentication',
        /* internal name: 'pages-authentication' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'forgot-password-v1',
            name: 'pages-authentication-forgot-password-v1',
            component: () => import('/vercel/path0/src/pages/pages/authentication/forgot-password-v1.vue'),
            /* no children */
          },
  _definePage_default_28
  ),
  _mergeRouteRecord(
          {
            path: 'forgot-password-v2',
            name: 'pages-authentication-forgot-password-v2',
            component: () => import('/vercel/path0/src/pages/pages/authentication/forgot-password-v2.vue'),
            /* no children */
          },
  _definePage_default_29
  ),
  _mergeRouteRecord(
          {
            path: 'login-v1',
            name: 'pages-authentication-login-v1',
            component: () => import('/vercel/path0/src/pages/pages/authentication/login-v1.vue'),
            /* no children */
          },
  _definePage_default_30
  ),
  _mergeRouteRecord(
          {
            path: 'login-v2',
            name: 'pages-authentication-login-v2',
            component: () => import('/vercel/path0/src/pages/pages/authentication/login-v2.vue'),
            /* no children */
          },
  _definePage_default_31
  ),
  _mergeRouteRecord(
          {
            path: 'register-multi-steps',
            name: 'pages-authentication-register-multi-steps',
            component: () => import('/vercel/path0/src/pages/pages/authentication/register-multi-steps.vue'),
            /* no children */
          },
  _definePage_default_32
  ),
  _mergeRouteRecord(
          {
            path: 'register-v1',
            name: 'pages-authentication-register-v1',
            component: () => import('/vercel/path0/src/pages/pages/authentication/register-v1.vue'),
            /* no children */
          },
  _definePage_default_33
  ),
  _mergeRouteRecord(
          {
            path: 'register-v2',
            name: 'pages-authentication-register-v2',
            component: () => import('/vercel/path0/src/pages/pages/authentication/register-v2.vue'),
            /* no children */
          },
  _definePage_default_34
  ),
  _mergeRouteRecord(
          {
            path: 'reset-password-v1',
            name: 'pages-authentication-reset-password-v1',
            component: () => import('/vercel/path0/src/pages/pages/authentication/reset-password-v1.vue'),
            /* no children */
          },
  _definePage_default_35
  ),
  _mergeRouteRecord(
          {
            path: 'reset-password-v2',
            name: 'pages-authentication-reset-password-v2',
            component: () => import('/vercel/path0/src/pages/pages/authentication/reset-password-v2.vue'),
            /* no children */
          },
  _definePage_default_36
  ),
  _mergeRouteRecord(
          {
            path: 'two-steps-v1',
            name: 'pages-authentication-two-steps-v1',
            component: () => import('/vercel/path0/src/pages/pages/authentication/two-steps-v1.vue'),
            /* no children */
          },
  _definePage_default_37
  ),
  _mergeRouteRecord(
          {
            path: 'two-steps-v2',
            name: 'pages-authentication-two-steps-v2',
            component: () => import('/vercel/path0/src/pages/pages/authentication/two-steps-v2.vue'),
            /* no children */
          },
  _definePage_default_38
  ),
  _mergeRouteRecord(
          {
            path: 'verify-email-v1',
            name: 'pages-authentication-verify-email-v1',
            component: () => import('/vercel/path0/src/pages/pages/authentication/verify-email-v1.vue'),
            /* no children */
          },
  _definePage_default_39
  ),
  _mergeRouteRecord(
          {
            path: 'verify-email-v2',
            name: 'pages-authentication-verify-email-v2',
            component: () => import('/vercel/path0/src/pages/pages/authentication/verify-email-v2.vue'),
            /* no children */
          },
  _definePage_default_40
  )
        ],
      },
      {
        path: 'brief-mgmt',
        /* internal name: 'pages-brief-mgmt' */
        /* no component */
        children: [
          {
            path: '',
            name: 'pages-brief-mgmt',
            component: () => import('/vercel/path0/src/pages/pages/brief-mgmt/index.vue'),
            /* no children */
          },
          {
            path: ':id',
            name: 'pages-brief-mgmt-id',
            component: () => import('/vercel/path0/src/pages/pages/brief-mgmt/[id].vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'cards',
        /* internal name: 'pages-cards' */
        /* no component */
        children: [
          {
            path: 'card-actions',
            name: 'pages-cards-card-actions',
            component: () => import('/vercel/path0/src/pages/pages/cards/card-actions.vue'),
            /* no children */
          },
          {
            path: 'card-advance',
            name: 'pages-cards-card-advance',
            component: () => import('/vercel/path0/src/pages/pages/cards/card-advance.vue'),
            /* no children */
          },
          {
            path: 'card-basic',
            name: 'pages-cards-card-basic',
            component: () => import('/vercel/path0/src/pages/pages/cards/card-basic.vue'),
            /* no children */
          },
          {
            path: 'card-statistics',
            name: 'pages-cards-card-statistics',
            component: () => import('/vercel/path0/src/pages/pages/cards/card-statistics.vue'),
            /* no children */
          },
          {
            path: 'card-widgets',
            name: 'pages-cards-card-widgets',
            component: () => import('/vercel/path0/src/pages/pages/cards/card-widgets.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'client',
        /* internal name: 'pages-client' */
        /* no component */
        children: [
          {
            path: '',
            name: 'pages-client',
            component: () => import('/vercel/path0/src/pages/pages/client/index.vue'),
            /* no children */
          },
          {
            path: ':id',
            /* internal name: 'pages-client-id' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':tab',
                name: 'pages-client-id-tab',
                component: () => import('/vercel/path0/src/pages/pages/client/[id]/[tab].vue'),
                /* no children */
              },
  _definePage_default_41
  )
            ],
          }
        ],
      },
      {
        path: 'client_test',
        name: 'pages-client-test',
        component: () => import('/vercel/path0/src/pages/pages/client_test.vue'),
        /* no children */
      },
      {
        path: 'client-brief-management',
        name: 'pages-client-brief-management',
        component: () => import('/vercel/path0/src/pages/pages/client-brief-management.vue'),
        /* no children */
      },
      {
        path: 'client-projects',
        name: 'pages-client-projects',
        component: () => import('/vercel/path0/src/pages/pages/client-projects.vue'),
        /* no children */
      },
      {
        path: 'client-reports',
        name: 'pages-client-reports',
        component: () => import('/vercel/path0/src/pages/pages/client-reports.vue'),
        /* no children */
      },
      {
        path: 'client-request-access',
        name: 'pages-client-request-access',
        component: () => import('/vercel/path0/src/pages/pages/client-request-access.vue'),
        /* no children */
      },
      {
        path: 'client-request-data-access',
        name: 'pages-client-request-data-access',
        component: () => import('/vercel/path0/src/pages/pages/client-request-data-access.vue'),
        /* no children */
      },
      {
        path: 'client-team-management',
        name: 'pages-client-team-management',
        component: () => import('/vercel/path0/src/pages/pages/client-team-management.vue'),
        /* no children */
      },
  _mergeRouteRecord(
      {
        path: 'connect-:id',
        name: 'pages-connect-id',
        component: () => import('/vercel/path0/src/pages/pages/connect-[id].vue'),
        /* no children */
      },
  _definePage_default_42
  ),
      {
        path: 'data-mgmt',
        /* internal name: 'pages-data-mgmt' */
        /* no component */
        children: [
          {
            path: '',
            name: 'pages-data-mgmt',
            component: () => import('/vercel/path0/src/pages/pages/data-mgmt/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'dialog-examples',
        /* internal name: 'pages-dialog-examples' */
        /* no component */
        children: [
          {
            path: '',
            name: 'pages-dialog-examples',
            component: () => import('/vercel/path0/src/pages/pages/dialog-examples/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'faq',
        name: 'pages-faq',
        component: () => import('/vercel/path0/src/pages/pages/faq.vue'),
        /* no children */
      },
      {
        path: 'flow',
        name: 'pages-flow',
        component: () => import('/vercel/path0/src/pages/pages/flow.vue'),
        /* no children */
      },
      {
        path: 'form',
        /* internal name: 'pages-form' */
        /* no component */
        children: [
          {
            path: '',
            name: 'pages-form',
            component: () => import('/vercel/path0/src/pages/pages/form/index.vue'),
            /* no children */
          },
          {
            path: ':id',
            name: 'pages-form-id',
            component: () => import('/vercel/path0/src/pages/pages/form/[id].vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'icons',
        name: 'pages-icons',
        component: () => import('/vercel/path0/src/pages/pages/icons.vue'),
        /* no children */
      },
      {
        path: 'misc',
        /* internal name: 'pages-misc' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'coming-soon',
            name: 'pages-misc-coming-soon',
            component: () => import('/vercel/path0/src/pages/pages/misc/coming-soon.vue'),
            /* no children */
          },
  _definePage_default_43
  ),
  _mergeRouteRecord(
          {
            path: 'under-maintenance',
            name: 'pages-misc-under-maintenance',
            component: () => import('/vercel/path0/src/pages/pages/misc/under-maintenance.vue'),
            /* no children */
          },
  _definePage_default_44
  )
        ],
      },
      {
        path: 'new-report',
        /* internal name: 'pages-new-report' */
        /* no component */
        children: [
          {
            path: '',
            name: 'pages-new-report',
            component: () => import('/vercel/path0/src/pages/pages/new-report/index.vue'),
            /* no children */
          },
  _mergeRouteRecord(
          {
            path: ':id',
            name: 'pages-new-report-id',
            component: () => import('/vercel/path0/src/pages/pages/new-report/[id].vue'),
            /* no children */
          },
  _definePage_default_45
  ),
          {
            path: 'embed',
            name: 'pages-new-report-embed',
            component: () => import('/vercel/path0/src/pages/pages/new-report/embed.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'playbook',
        /* internal name: 'pages-playbook' */
        /* no component */
        children: [
          {
            path: '',
            name: 'pages-playbook',
            component: () => import('/vercel/path0/src/pages/pages/playbook/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'pricing',
        /* internal name: 'pages-pricing' */
        /* no component */
        children: [
          {
            path: '',
            name: 'pages-pricing',
            component: () => import('/vercel/path0/src/pages/pages/pricing/index.vue'),
            /* no children */
          },
  _mergeRouteRecord(
          {
            path: 'callback',
            name: 'pages-pricing-callback-parent',
            component: () => import('/vercel/path0/src/pages/pages/pricing/callback.vue'),
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'pages-pricing-callback',
                component: () => import('/vercel/path0/src/pages/pages/pricing/callback/index.vue'),
                /* no children */
              },
  _definePage_default_46
  )
            ],
          },
  _definePage_default_47
  )
        ],
      },
      {
        path: 'project',
        /* internal name: 'pages-project' */
        /* no component */
        children: [
          {
            path: '',
            name: 'pages-project',
            component: () => import('/vercel/path0/src/pages/pages/project/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'report',
        /* internal name: 'pages-report' */
        /* no component */
        children: [
          {
            path: '',
            name: 'pages-report',
            component: () => import('/vercel/path0/src/pages/pages/report/index.vue'),
            /* no children */
          },
          {
            path: ':id',
            name: 'pages-report-id',
            component: () => import('/vercel/path0/src/pages/pages/report/[id].vue'),
            /* no children */
          },
          {
            path: 'custom-reports-:id',
            name: 'pages-report-custom-reports-id',
            component: () => import('/vercel/path0/src/pages/pages/report/custom-reports-[id].vue'),
            /* no children */
          },
          {
            path: 'edit-:id',
            name: 'pages-report-edit-id',
            component: () => import('/vercel/path0/src/pages/pages/report/edit-[id].vue'),
            /* no children */
          },
          {
            path: 'preview-report-:id',
            name: 'pages-report-preview-report-id',
            component: () => import('/vercel/path0/src/pages/pages/report/preview-report-[id].vue'),
            /* no children */
          },
  _mergeRouteRecord(
          {
            path: 'public-report',
            name: 'pages-report-public-report',
            component: () => import('/vercel/path0/src/pages/pages/report/public-report.vue'),
            /* no children */
          },
  _definePage_default_48
  )
        ],
      },
      {
        path: 'request-access',
        /* internal name: 'pages-request-access' */
        /* no component */
        children: [
          {
            path: '',
            name: 'pages-request-access',
            component: () => import('/vercel/path0/src/pages/pages/request-access/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'setting',
        /* internal name: 'pages-setting' */
        /* no component */
        children: [
          {
            path: '',
            name: 'pages-setting',
            component: () => import('/vercel/path0/src/pages/pages/setting/index.vue'),
            /* no children */
          },
          {
            path: 'ProfilePicture',
            name: 'pages-setting-profile-picture',
            component: () => import('/vercel/path0/src/pages/pages/setting/ProfilePicture.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'staff',
        name: 'pages-staff',
        component: () => import('/vercel/path0/src/pages/pages/staff.vue'),
        /* no children */
      },
      {
        path: 'team-member',
        /* internal name: 'pages-team-member' */
        /* no component */
        children: [
          {
            path: '',
            name: 'pages-team-member',
            component: () => import('/vercel/path0/src/pages/pages/team-member/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'template',
        /* internal name: 'pages-template' */
        /* no component */
        children: [
          {
            path: '',
            name: 'pages-template',
            component: () => import('/vercel/path0/src/pages/pages/template/index.vue'),
            /* no children */
          },
          {
            path: ':id',
            name: 'pages-template-id',
            component: () => import('/vercel/path0/src/pages/pages/template/[id].vue'),
            /* no children */
          },
          {
            path: 'form',
            /* internal name: 'pages-template-form' */
            /* no component */
            children: [
              {
                path: '',
                name: 'pages-template-form',
                component: () => import('/vercel/path0/src/pages/pages/template/form/index.vue'),
                /* no children */
              },
              {
                path: ':id',
                name: 'pages-template-form-id',
                component: () => import('/vercel/path0/src/pages/pages/template/form/[id].vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'typography',
        name: 'pages-typography',
        component: () => import('/vercel/path0/src/pages/pages/typography.vue'),
        /* no children */
      },
      {
        path: 'user-profile',
        /* internal name: 'pages-user-profile' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':tab',
            name: 'pages-user-profile-tab',
            component: () => import('/vercel/path0/src/pages/pages/user-profile/[tab].vue'),
            /* no children */
          },
  _definePage_default_49
  )
        ],
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/register',
    name: 'register',
    component: () => import('/vercel/path0/src/pages/register.vue'),
    /* no children */
  },
  _definePage_default_50
  ),
  {
    path: '/setting',
    /* internal name: 'setting' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'setting',
        component: () => import('/vercel/path0/src/pages/setting/index.vue'),
        /* no children */
      },
  _definePage_default_51
  ),
      {
        path: 'ProfilePicture',
        name: 'setting-profile-picture',
        component: () => import('/vercel/path0/src/pages/setting/ProfilePicture.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/shopify',
    /* internal name: 'shopify' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: 'callback',
        name: 'shopify-callback',
        component: () => import('/vercel/path0/src/pages/shopify/callback.vue'),
        /* no children */
      },
  _definePage_default_52
  )
    ],
  },
  {
    path: '/tables',
    /* internal name: 'tables' */
    /* no component */
    children: [
      {
        path: 'data-table',
        name: 'tables-data-table',
        component: () => import('/vercel/path0/src/pages/tables/data-table.vue'),
        /* no children */
      },
      {
        path: 'simple-table',
        name: 'tables-simple-table',
        component: () => import('/vercel/path0/src/pages/tables/simple-table.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/wizard-examples',
    /* internal name: 'wizard-examples' */
    /* no component */
    children: [
      {
        path: 'checkout',
        name: 'wizard-examples-checkout',
        component: () => import('/vercel/path0/src/pages/wizard-examples/checkout.vue'),
        /* no children */
      },
      {
        path: 'create-deal',
        name: 'wizard-examples-create-deal',
        component: () => import('/vercel/path0/src/pages/wizard-examples/create-deal.vue'),
        /* no children */
      },
      {
        path: 'property-listing',
        name: 'wizard-examples-property-listing',
        component: () => import('/vercel/path0/src/pages/wizard-examples/property-listing.vue'),
        /* no children */
      }
    ],
  }
]
