<script setup>
import { useSocialConnection } from "@/composables/store/useSocialConnection";
import { onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
const socialConnectionStore = useSocialConnection();

definePage({
  meta: {
    layout: "blank",
    public: true,
  },
});
onMounted(async () => {
  try {
    const code = route.query.code;
    console.log("code", code);

    if (!code) {
      throw new Error("No authorization code received from Amazon");
    }

    const params = {
      auth_code: code,
      type: "amazon",
    };

    console.log("Sending params to finish API:", params);

    // Call the finish API with the parameters
    const response = await socialConnectionStore.finishAmazonAuth(params);

    if (response.success) {
      // Redirect to data sources page with success message
      router.push({
        name: "onboarding",
        query: { activeTab: 1 },
      });
    } else {
      throw new Error(response.message || "Failed to connect Amazon account");
    }
  } catch (error) {
    console.error("Error in Amazon callback:", error);
    // Redirect to data sources page with error message
    router.push({
      name: "onboarding",
      query: { activeTab: 1 },
    });
  }
});
</script>

<template>
  <div class="flex flex-col items-center justify-center min-h-screen">
    <VProgressCircular indeterminate color="primary" :size="50" :width="3" />
    <p class="mt-4 text-gray-600">Connecting your Amazon account...</p>
  </div>
</template>
