<script setup>
import { useAuthStore } from "@/composables/store/useAuthStore";
import { useErrorStore } from "@/composables/store/useErrorStore";
import { apiInstance } from "@/utils/axios";
import { LOCALSTORAGE } from "@/utils/constants";
import { onMounted, ref } from "vue";

definePage({
  meta: {
    layout: "blank",
    public: true,
  },
});

const errorStore = useErrorStore();
const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();
const isLoading = ref(true);
const processingStep = ref("Initializing...");

const finishMetaAuth = async () => {
  try {
    // Clear any existing error messages
    errorStore.clearErrorMessage();

    const { access_token, auth_code, agency_id, client_id } = route.query;

    // Validate required parameters
    if (!access_token && !auth_code) {
      throw new Error("Missing required authentication parameters");
    }

    // Validate company ID
    const companyId = localStorage.getItem(LOCALSTORAGE.COMPANY_ID);
    if (!companyId) {
      throw new Error("Company information not found");
    }

    processingStep.value = "Authenticating with Meta...";

    const response = await apiInstance.post(
      `${import.meta.env.VITE_SECONDARY_API_BASE_URL}/auth/meta/finish`,
      {
        company_id: companyId,
        access_service: "meta_ads",
        access_token,
        auth_code,
        agency_id: agency_id || null,
        client_id: client_id || null,
      }
    );

    if (!response?.data?.success) {
      throw new Error(
        response?.data?.message || "Failed to complete Meta authentication"
      );
    }

    processingStep.value = "Updating company information...";
    await authStore.getCompany(authStore.company?.id);

    // Handle successful authentication
    errorStore.setErrorMessage("Successfully connected to Meta Ads");

    // Redirect based on company type with proper error handling
    if (!authStore.company?.company_type) {
      throw new Error("Company type not found");
    }

    const redirectPath =
      authStore.company.company_type === "agency"
        ? { name: "onboarding", query: { activeTab: 1 } }
        : // Go to specific page url
          { path: `/pages/connect-${authStore.currentAgency.name}` };

    router.push(redirectPath);
  } catch (err) {
    const errorMessage = err.response?.data?.message || err.message;

    errorStore.setErrorMessage(errorMessage);

    router.push({
      name: "onboarding",
      query: {
        activeTab: 1,
        error: errorMessage,
      },
    });
  } finally {
    isLoading.value = false;
  }
};

onMounted(async () => {
  console.log("Callback mounted, route query params:", route.query);

  await finishMetaAuth();
});
</script>

<template>
  <div class="meta-callback-container">
    <VCard class="pa-6 mx-auto" max-width="600">
      <VCardText class="text-center">
        <h1 class="text-h4 mb-6">
          {{ error || "Connecting to Meta Ads" }}
        </h1>

        <template v-if="isLoading">
          <VProgressCircular
            size="100"
            indeterminate
            color="primary"
            class="mb-4"
          />
          <div class="text-body-1 text-medium-emphasis">
            {{ processingStep }}
          </div>
        </template>

        <VSlideYTransition>
          <div v-if="error" class="mt-4">
            <p class="text-error mb-4">{{ error }}</p>
            <VBtn
              color="primary"
              prepend-icon="mdi-arrow-left"
              @click="router.push('/pages/request-access')"
            >
              Return to Request Access
            </VBtn>
          </div>
        </VSlideYTransition>
      </VCardText>
    </VCard>
  </div>
</template>

<style scoped>
.meta-callback-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: rgb(var(--v-theme-background));
  min-block-size: 100vh;
}

.text-error {
  color: rgb(var(--v-theme-error));
}
</style>
