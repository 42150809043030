export const COOKIE_MAX_AGE_1_YEAR = 365 * 24 * 60 * 60;

export const LOCALSTORAGE = {
  HASURA_ACCESS_TOKEN: "hasura-access-token",
  USER_DATA: "user-data",
  USER_ID: "user-id",
  COMPANY_DATA: "company-data",
  COMPANY_ID: "company-id",
  COMPANY_TYPE: "company-type",
  SUBSCRIPTION_STATUS: "subscription-status",
  SUBSCRIPTION_KEY: "subscription-key",
  SUBSCRIPTION_START_DATE: "subscription-start-date",
  SUBSCRIPTION_END_DATE: "subscription-end-date",
  USER_ROLE: "user-role",
  AGENCY_ID: "agency-id",
  AGENCY_NAME: "agency-name",
  AGENCY_TYPE: "agency-type",
  AGENCY_WEBSITE: "agency-website",
  AGENCY_EMAIL: "agency-email",
};

export const ERROR_MESSAGES = {
  SERVER_ERROR: "Server error",
  WRONG_CREDENTIALS_ERROR: "Wrong email and/or password",
};

export const RoleActions = {
  None: "none",
  Create: "create",
  Read: "read",
  Update: "update",
  Delete: "delete",
};

export const RoleSubjects = {
  None: "none",
  All: "all",
  AllAgency: "all-agency",
  AllClient: "all-client",
  AgencyDashboard: "agency-dashboard",
  AgencyClient: "agency-client",
  AgencyProject: "agency-project",
  AgencyAccessManagement: "agency-access-management",
  AgencyBrief: "agency-brief",
  AgencyTeamMembers: "agency-team-members",
  AgencyTemplates: "agency-templates",
  AgencyReports: "agency-reports",
  AgencyPricing: "agency-pricing",
  AgencyProfileSettings: "agency-profile-settings",
  AgencyBillingSettings: "agency-billing-settings",
  AgencyCompanySettings: "agency-company-settings",
  ClientDashboard: "client-dashboard",
  ClientProject: "client-project",
  ClientBrief: "client-brief",
  ClientRequestAccess: "client-request-access",
  ClientTeamMembers: "client-team-members",
  ClientTemplates: "client-templates",
  ClientReports: "client-reports",
  ClientProfileSettings: "client-profile-settings",
  ClientCompanySettings: "client-company-settings",
};

export const Roles = {
  SuperAdmin: "superadmin",
  AgencyAdmin: "agency_admin",
  AgencyManager: "agency_manager",
  AgencyViewer: "agency_viewer",
  ClientAdmin: "client",
  ClientManager: "client_manager",
  ClientViewer: "client_viewer",
};
