<script setup>
import { useErrorStore } from "@/composables/store/useErrorStore";
import { useSuccessStore } from "@/composables/store/useSuccessStore";
import ScrollToTop from "@core/components/ScrollToTop.vue";
import initCore from "@core/initCore";
import { initConfigStore, useConfigStore } from "@core/stores/config";
import { hexToRgb } from "@layouts/utils";
import { storeToRefs } from "pinia";
import { watch } from "vue";
import { useTheme } from "vuetify";

const { global } = useTheme();

// ℹ️ Sync current theme with initial loader theme
initCore();
initConfigStore();

// Initizalize auth store if user is already logged in

const configStore = useConfigStore();
const errorStore = useErrorStore();
const successStore = useSuccessStore();
const { successMessage } = storeToRefs(successStore);
const { errorMessage } = storeToRefs(errorStore);
const isError = ref(true);
const isSuccess = ref(true);

watch(
  errorMessage,
  (value) => {
    if (!value) {
      errorStore.clearErrorMessage();
      isError.value = false;

      return;
    }
    isError.value = true;
  },
  {
    immediate: true,
  }
);

watch(
  isError,
  (value) => {
    if (!value) errorStore.clearErrorMessage();
  },
  {
    immediate: true,
  }
);

watch(
  successMessage,
  (value) => {
    if (!value) {
      successStore.clearSuccessMessage();
      isSuccess.value = false;

      return;
    }
    isSuccess.value = true;
  },
  {
    immediate: true,
  }
);

watch(
  isSuccess,
  (value) => {
    if (!value) successStore.clearSuccessMessage();
  },
  {
    immediate: true,
  }
);
</script>

<template>
  <VLocaleProvider :rtl="configStore.isAppRTL">
    <!-- ℹ️ This is required to set the background color of active nav link based on currently active global theme's primary -->
    <VApp
      :style="`--v-global-theme-primary: ${hexToRgb(
        global.current.value.colors.primary
      )}`"
    >
      <RouterView />
      <ScrollToTop />
      <VSnackbar v-model="isSuccess" close-delay="3000">
        <template #actions>
          <VBtn
            timeout="3000"
            color="success"
            @click="successStore.clearSuccessMessage"
          >
            Close
          </VBtn>
        </template>
        <span>
          {{ successMessage }}
        </span>
      </VSnackbar>
      <VSnackbar v-model="isError" close-delay="3000">
        <template #actions>
          <VBtn
            timeout="3000"
            color="error"
            @click="errorStore.clearErrorMessage"
          >
            Close
          </VBtn>
        </template>
        <span>
          {{ errorMessage }}
        </span>
      </VSnackbar>
      <div id="nav-drawer"></div>
    </VApp>
  </VLocaleProvider>
</template>
